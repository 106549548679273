import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { GeneralService } from './../../../_services/general/general.service';
import {
  hasEarlyRegistration,
  Namskeid,
  PortalData,
} from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';
import {
  Language,
  LanguageService,
} from '@endurmenntun/_services/language.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'en-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.less'],
})
export class CourseCardComponent implements OnInit {
  @Input() state?;
  // Course content from parent
  @Input() cont;
  // Course Type
  @Input() cardType: 'large';
  @Input() loaded: Observable<boolean>;
  @Input() offset: number;
  public data: Namskeid;
  public gallery: PortalData;
  public seats;
  public date;
  public lastDate;
  public past;
  public started;
  public language: Language;
  public has_early_registration: Boolean;
  public isRemote: Boolean;
  public isNew: Boolean;

  constructor(public general: GeneralService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.data = this.cont.fieldData;
    this.gallery = this.cont.portalData;
    this.seats = this.general.formatSeats(this.data.Fjoldi_laus_saeti);
    this.formatCourseDate();
    this.past = this.general.checkIfPast(this.data.Endar_Dags);
    this.started = this.general.checkIfStarted(
      this.data.Byrjar_Dags,
      this.data.Hefst_KL
    );
    this.language = LanguageService.getCourseLanguage(this.data);
    this.has_early_registration = this.hasEarlyRegistration(this.data);
    this.checkRemoteQuery();

  }

  // to check if course is remote, new or not - fjarnám, nytt
  private checkRemoteQuery() {
    this.route.queryParams.subscribe(params => {
      this.isRemote = params['remote'] === 'true';
      this.isNew = params['sort'] === 'new';
    });
  }

  formatCourseDate() {
    const format = "d. MMM ''yy";
    const locale = 'is-IS';

    if (this.data.Endar_Dags !== this.data.Byrjar_Dags) {
      const formatNoYear = 'd. MMM';
      const thedateStart = new Date(this.data.Byrjar_Dags);
      this.date = formatDate(
        thedateStart,
        this.data.ID_Yfirflokkur.includes('Y0004') ? format : formatNoYear,
        locale
      );
      const thedate = new Date(this.data.Endar_Dags);
      this.lastDate = formatDate(thedate, format, locale);
    } else {
      const thedate = new Date(this.data.Byrjar_Dags);
      this.date = formatDate(thedate, format, locale);
    }
  }

  /** Get the course router link */
  getCourseLink(type: 'namsbrautir' | 'namskeid', _data: Namskeid) {
    return `/${type}/${this.cont.eduframeCourse.id}/${this.cont.eduframeCourse.slug}/${this.cont.eduframePlannedCourse.id}`;
  }

  /** Detect if this course offers an early registration */
  private hasEarlyRegistration(course: Namskeid): Boolean {
    return hasEarlyRegistration(course);
  }

  private get Language() {
    return Language;
  }
}
