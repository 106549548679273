import {
  Component,
  OnInit,
  ElementRef,
  Input,
  HostListener,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CourseVariant,
  CourseVariantsService,
  Label,
  LabelsService,
} from 'src/eduframe';
import { environment } from 'src/environments/environment';
import { GlobalVariablesService } from '../../../_services/globalVariables/globalVariables.service';

interface FilterCategory {
  name: string;
  items: FilterItem[];
}

interface FilterItem {
  id: number;
  name: string;
  label: Label;
}

@Component({
  selector: 'en-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.less'],
})
export class FilterComponent implements OnInit {
  @Input() activeCourseType: string;
  @Input() color: string;

  // Active filtering
  public activeCat: any;
  public whiteBackground = false;
  public activeTag: any;
  public activeFilter: string | undefined; // Ensure activeFilter is declared here

  // categories dropdown
  public categoriesActive = false;

  // Eduframe approach
  public eduframeLabels: Label[];
  public eduframeCourseVariants: CourseVariant[];
  public filters: FilterCategory[] = [];
  public courseVariantId: number;
  // Filter button visibility
  public courseFilterIsActive: boolean;

  @HostListener('document:click', ['$event'])
  clickout() {
    if (
      this.eRef.nativeElement.querySelector('.categories') &&
      !this.eRef.nativeElement
        .querySelector('.categories')
        .contains(event.target)
    ) {
      this.categoriesActive = false;
    }
  }

  constructor(
    private eRef: ElementRef,
    private route: ActivatedRoute,
    private eduframeLabelService: LabelsService,
    private eduframeCourseVariantsService: CourseVariantsService,
    private globalVariablesService: GlobalVariablesService
  ) {
    this.route.queryParams.subscribe(params => {
      this.courseVariantId = params.course_variant_id;
    });
  }

  ngOnInit(): void {
    this.getFilters();
    this.getCourseVariants();

    // Get label_id from query parameters and set active filter on refresh
    this.route.queryParams.subscribe(params => {
      const labelId = params.label_id; // Get the label_id from query params

      if (labelId) {
        this.setActiveFilterById(labelId); // Set the active filter by label_id
      } else {
        // If label_id is not present, clear the active filter
        this.clearActiveFilter();
      }
    });

    this.globalVariablesService.courseFilterIsActive$.subscribe(
      (state: boolean) => {
        this.courseFilterIsActive = state;
      }
    );

    this.globalVariablesService.activeFilter$.subscribe(
      (filter: string | undefined) => {
        this.activeFilter = filter;
      }
    );
  }

  private getCourseVariants(): void {
    this.eduframeCourseVariantsService
      .getCourseVariants()
      .subscribe((courseVariants: CourseVariant[]) => {
        this.eduframeCourseVariants = courseVariants.filter(
          (courseVariant: CourseVariant) => courseVariant.name === 'Fjarnám'
        );
      });
  }

  private getFilters(): void {
    this.eduframeLabelService
      .getLabels({ modelType: 'Catalog::Product', perPage: 500 })
      .subscribe((labels: Label[]) => {
        if (this.activeCourseType === 'Y0004') {
          // Exclude labels starting with 'Nám'
          this.filters.push({
            name: 'Hvað viltu læra?',
            items: labels
              .filter(label => label.name.startsWith('Nám'))
              .map(label => ({
                id: label.id,
                name: label.name,
                label,
              })),
          });
        } else {
          // Only include labels starting with 'Nám'
          this.filters.push({
            name: 'Hvað viltu læra?',
            items: labels
              .filter(label => !label.name.startsWith('Nám'))
              .map(label => ({
                id: label.id,
                name: label.name,
                label,
              })),
          });
        }

        // Now that filters are loaded, check query parameters for label_id
        const labelId = this.route.snapshot.queryParams['label_id'];
        if (labelId) {
          this.setActiveFilterById(labelId); // Set active filter after filters are loaded
        }
      });
  }


  private setActiveFilterById(labelId: string): void {
    if (!labelId) {
      this.clearActiveFilter();
      return;
    }

    for (const filterCategory of this.filters) {
      const matchedItem = filterCategory.items.find(
        item => item.label.id === +labelId
      );
      if (matchedItem) {
        this.activeFilter = matchedItem.label.name; // Set the active filter name
        this.activeTag = matchedItem.label.name; // Set the activeTag if needed
        this.globalVariablesService.setActiveFilter(matchedItem.label.name); // Update global filter state
        break;
      }
    }
  }

  private clearActiveFilter(): void {
    this.activeFilter = undefined; // Clear the active filter
    this.activeTag = undefined; // Clear the active tag
    this.globalVariablesService.setActiveFilter(undefined); // Reset the global filter state
  }

  public toggleCat(): void {
    this.categoriesActive
      ? (this.categoriesActive = false)
      : (this.categoriesActive = true);
  }

  public filterLabel(event: PointerEvent, label: Label): void {
    if (event.ctrlKey) {
      return;
    }

    this.globalVariablesService.setActiveFilter(label.name);
    this.categoriesActive = false;
    this.globalVariablesService.setButtonHiddenState(true);
  }

  public removeFilter(): void {
    this.globalVariablesService.setActiveFilter(undefined);
    this.globalVariablesService.setButtonHiddenState(false);
  }
}
