import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
// Services
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import { Namskeid } from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';
import { FmResponse } from './../../pages/education/interfaces/filemaker-query.interface';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';

@Component({
  selector: 'en-course-grid',
  templateUrl: './course-grid.component.html',
  styleUrls: ['./course-grid.component.less'],
})
export class CourseGridComponent implements OnInit {
  @Input() offset;
  public limit: number = 500;
  public courses: Array<{ fieldData?: Namskeid }>;
  public loaded: Observable<boolean>;

  constructor(
    private educationService: FakeMakerService,
    private general: GeneralService
  ) {}

  ngOnInit() {

    this.educationService
      .getCourses()
      .then(
        (data: FmResponse) => {
          this.courses = data.response.data;
        },
        err => {
          console.error(err);
        }
      )
      .then(() => {
        this.loaded = this.general.showContent();
      });
  }
}
