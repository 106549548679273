import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
import {
  hasEarlyRegistration,
  Namskeid,
} from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';
import {
  Language,
  LanguageService,
} from '@endurmenntun/_services/language.service';
import { Course, CoursesService } from 'src/eduframe';

@Component({
  selector: 'en-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.less'],
})
export class HeroBannerComponent implements OnChanges, OnInit {
  @Input() heroBannerContent: Namskeid | any;
  @Input() heroBannerGallery?;
  @Input() contentType;
  @Input() herobannerType: string;
  @Input() expired: string;
  @Input() loaded: Observable<boolean>;
  @Input() signupUrl?: string;
  @Output() pushSearchQuery = new EventEmitter<any>();
  public seats: number;
  public started;
  public color: string;
  public language: Language;
  public has_early_registration: Boolean;
  public course: Course;

  constructor(
    public general: GeneralService,
    public eduframeCoursesService: CoursesService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const hbc = changes.heroBannerContent;
    if (hbc) {
      this.getType();
      this.checkIfStarted();
      this.language = LanguageService.getCourseLanguage(this.heroBannerContent);
      this.has_early_registration = this.hasEarlyRegistration(
        this.heroBannerContent
      );
      if (hbc && hbc.currentValue?.hasOwnProperty('Fjoldi_laus_saeti')) {
        this.seats = this.general.formatSeats(
          this.heroBannerContent?.Fjoldi_laus_saeti
        );
      }
    }
  }

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;
  }

  getType() {
    if (this.heroBannerContent?.ID_Yfirflokkur === 'Y0004') {
      this.color = 'blue';
    }
  }

  search($event) {
    this.pushSearchQuery.emit($event);
  }

  checkIfStarted() {
    const now = new Date();
    const courseStartDate = new Date(
      this.heroBannerContent?.Byrjar_Dags +
        ' ' +
        this.heroBannerContent?.Hefst_KL
    );
    if (now > courseStartDate) {
      this.started = true;
    } else {
      this.started = false;
    }
  }

  /** Open registration form in a new tab */
  openSignupUrl() {
    window.open(
      this.signupUrl,
      '_blank'
    );
  }

  /** Detect if this course offers an early registration */
  private hasEarlyRegistration(course: Namskeid): Boolean {
    return hasEarlyRegistration(course);
  }

  /** Return Language enum, for template use */
  private get Language() {
    return Language;
  }
}
